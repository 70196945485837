import { createStore } from "vuex"
import createPersistedState from "vuex-persistedstate"

export interface State {
  // ユーザ情報
  id?: number
  name?: string
  isStudent?: boolean
  // 一覧のプルダウン・フリーワード検索
  grade_id: number
  subject_id: number
  sort_key: string
  school_id: number
  classroom_id: number
  course_id: number
  word: string
}

export const store = createStore<State>({
  state: {
    id: undefined,
    name: undefined,
    isStudent: undefined,
    grade_id: 0,
    subject_id: 0,
    sort_key: "id_desc",
    school_id: 0,
    classroom_id: 0,
    course_id: 0,
    word: "",
  },
  getters: {
    isTeacher(state) {
      return state.id && !state.isStudent
    },
    isStudent(state) {
      return state.id && state.isStudent
    },
    selection(state) {
      return {
        grade_id: state.grade_id,
        subject_id: state.subject_id,
        sort_key: state.sort_key,
        school_id: state.school_id,
        classroom_id: state.classroom_id,
        course_id: state.course_id,
        word: state.word,
      }
    },
  },
  mutations: {
    setPrincipal(state, principal) {
      state.id = principal.id
      state.name = principal.name
      state.isStudent = principal.is_student
    },
    setSelection(state, selection) {
      state.grade_id = selection.grade_id
      state.subject_id = selection.subject_id
      state.sort_key = selection.sort_key
      state.school_id = selection.school_id
      state.classroom_id = selection.classroom_id
      state.course_id = selection.course_id
      state.word = selection.word
    },
    reset(state) {
      state.id = undefined
      state.name = undefined
      state.isStudent = undefined
      state.grade_id = 0
      state.subject_id = 0
      state.sort_key = "id_desc"
      state.school_id = 0
      state.classroom_id = 0
      state.course_id = 0
      state.word = ""
    },
  },
  plugins: [createPersistedState({ storage: sessionStorage })],
})
