import https from "https"
import axios from "axios"

// CSRF対策
export const xsrfToken = () => {
  let xsrf = ""
  document.cookie.split(";").forEach((cookie) => {
    const token = cookie.split("=")
    if (token[0] && token[0].trim().toUpperCase() === "XSRF-TOKEN") {
      xsrf = token[1].trim()
      return
    }
  })
  return xsrf
}

export const http = axios.create({
  baseURL: "/api",
  withCredentials: true,
  httpsAgent: new https.Agent({
    rejectUnauthorized: false,
  }),
  responseType: "json",
})

export const log = (api: string, message: string) => {
  const data = new FormData()
  const _api = api.startsWith("/") ? "/api" + api : api
  data.append("_csrf", xsrfToken())
  data.append("api", _api)
  data.append("message", message)
  data.append("occurred_at", new Date().getTime().toString())
  navigator.sendBeacon("/api/common/log", data)
}
