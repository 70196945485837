import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"
import HomeView from "@/views/HomeView.vue"
import FileListView from "@/views/FileListView.vue"
import FileAddView from "@/views/FileAddView.vue"
import FileEditView from "@/views/FileEditView.vue"
import FileShowView from "@/views/FileShowView.vue"
import StudentListView from "@/views/StudentListView.vue"
import StudentAddView from "@/views/StudentAddView.vue"
import StudentEditView from "@/views/StudentEditView.vue"
import PasswordView from "@/views/PasswordView.vue"
import HistoryView from "@/views/HistoryView.vue"
import CocoStudyView from "@/views/CocoStudyView.vue"
import TeacherCocoStudyView from "@/views/TeacherCocoStudyView.vue"
import CocoStudyMaterialView from "@/views/CocoStudyMaterialView.vue"
import MaterialListView from "@/views/MaterialListView.vue"

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/files",
    name: "fileListView",
    component: FileListView,
  },
  {
    path: "/file/add",
    name: "fileAddView",
    component: FileAddView,
  },
  {
    path: "/file/show/:id",
    name: "fileShowView",
    component: FileShowView,
  },
  {
    path: "/file/:id/edit",
    name: "fileEditView",
    component: FileEditView,
  },
  {
    path: "/students",
    name: "studentListView",
    component: StudentListView,
  },
  {
    path: "/student/add",
    name: "studentAddView",
    component: StudentAddView,
  },
  {
    path: "/student/:id/edit",
    name: "studentEditView",
    component: StudentEditView,
  },
  {
    path: "/password",
    name: "passwordView",
    component: PasswordView,
  },
  {
    path: "/file/:id/histories",
    name: "historyView",
    component: HistoryView,
  },
  {
    path: "/coco-study",
    name: "cocoStudyView",
    component: CocoStudyView,
  },
  {
    path: "/coco-study/teacher",
    name: "teacherCocoStudyView",
    component: TeacherCocoStudyView,
  },
  {
    path: "/coco-study/material/:id",
    name: "cocoStudyMaterialView",
    component: CocoStudyMaterialView,
  },
  {
    path: "/materials",
    name: "materialListView",
    component: MaterialListView,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
